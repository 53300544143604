<template>
  <div class="games">
    <div class="topBanner">
      <img style=" width: 34%;" src="@/assets/img/gameBanner.png" alt="">
      <div class="topBannerContent">
        <h2>GAME</h2>
        <p>With its mission to Bring Happiniess to All Players in mind, Coin Tech is committed to explore advancing technology, developing creative content and ensuring gaming experiences beyond all expectations.</p>
      </div>
    </div>
    <div class="game">
      <div class="gameContent">
        <div>
          <h2>Shing Ace Max</h2>
          <p>Embark on an exciting open world adventure together in the world of Shining Ace Max! Together with your companions, forge unforgettable memories as you unravel the secrets of this vibrant world.</p>
          <a href="https://play.google.com/store/apps/details?id=com.co.sev.max&hl=en&gl=hk" target="_blank" rel="noopener noreferrer"><img src="@/assets/img/google.png" class="google" alt=""></a>
        </div>
        <img class="gameLogo" src="@/assets/img/game2.png" alt="">
      </div>
    </div>
    <div class="game">
      <div class="gameContent">
        <img class="gameLogo" src="@/assets/img/game1.png" alt="">
        <div>
          <h2>NAME OF GAME</h2>
          <p>We have a diverse portfolio of games, different categories ranging from 3D Strategy RPG, Sports games, and Instant Casual games. Amusement Planet aims to deliver high-quality Online Entertainment with the Best of Digital Technology.</p>
          <img class="google" src="@/assets/img/google.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {

    }
  },
  components: {
  },
  computed: {
    ...Vuex.mapGetters([
      "bannerImg",
      "headerImg",
    ])
  },

}
</script>
<style lang="stylus">
.games {
  background-color: #f3f3f3;
  padding-top: .98rem;
  .topBanner {
    margin: 0 auto;
  }
  & > div {
    font-family: gilroy;
    &:nth-child(even) {
      background-color: #fff;
    }
    h2 {
      font-weight: bold;
      font-size: .5rem;
      font-family: gotham;
    }
  }
  .topBanner {
    flex( row, nowrap, center, center );
    h2 {
      font-size: 1rem;
    }
    img {
      height: auto;
      margin-right: 5%;
      margin-left: 4%;
    }
    .topBannerContent {
      width: 40%;
      text-align: left;
      margin-left: 5%;
      p {
        width: 34%;
        min-width: 4.2rem;
        margin: .1rem 0;
        line-height: .3rem;
        color: $C0;
        font-size: .18rem;
      }
    }
    
  }
  .game {
    font-size: .18rem;
    .gameContent {
      max-width: 11.4rem;
      flex(row, nowrap, space-between, center);
      margin: 0 auto;
      padding: .5rem .4rem;
      .gameLogo {
        height: 5.75rem;  
      }
      & >div {
        max-width: 4.2rem;
      }
      .google {
        margin-top: .3rem;
        cursor: pointer;
        width: 1.6rem;
      }
        
    }  
    p {
      margin: .2rem auto 0;
      line-height: .3rem;
      color: $C0;
    }
  }
}
</style>